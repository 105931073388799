#modSkillWrapper {
	display: flex;
	flex-direction: row;
	margin-top: 1em
}

#modSkillMainTreeWrapper {
    flex: 1 1;
    /* text-align: right; */
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
	
	h2 {
		white-space: nowrap;
	}
}

#modSkillContent {
	width: max-content;
	border: 1px solid gray;
	padding:1em;
}

#descrizioneSkillNewInput {
	width: 25em;
}

.treeListItem.withTitle {
	flex-flow: column;
}

#detailSkillWrapper {
	flex: 1;
	text-align: left;
}