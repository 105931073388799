.treeContainer {

	ul {
		text-align: left;
		display: inline-block;
		list-style-type: none;
		padding-inline-start: 2em;

		li {
			position: relative;
			.toggleSymbol {
				position: absolute;
				left: 0;
			}

			&>div {
				margin-left: 3px !important;
				padding-left: 1em;
			}
		}

	}
}

.skillTreeComponentWrapper {
	ul {
		text-align: left;
		display: inline-block;
		list-style-type: none;
		padding-inline-start: 2em;

		li {
			position: relative;
			.toggleSymbol {
				position: absolute;
				left: 0;
			}

			.skillNode {
				margin-left: 0;
			}

			&>div {
				margin-left: 3px !important;
				padding-left: 0.8em;
			}
		}

	}
}