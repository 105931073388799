.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.treeListItem {
  display:flex;
  flex-direction: row;

  label {
    flex: 1;
    text-align: right;
    padding-right: 3px;
  }

  div {
    flex: 1;
    text-align: left;
  }
}

ul.formWrapper {
  display:flex;
  flex-direction: column;
  list-style-type: none;

  li {

    &.treeListItem {
      display: flex;
      flex: 1;
      padding: 10px;

      label {
        width: 5em;
        text-align: right;
        padding-right: 5px;
      }

      .rightColumn {
        flex: 1;
        text-align: left;
      }

      &.actionButton {
        text-align: center;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}
